'use client'

import { StoryblokComponent } from '@storyblok/react/rsc'
import type { AlertStoryblok } from '@vendure/codegen/storyblok'
import {
  AlertDescription,
  AlertTitle,
  Alert as CustomAlert,
} from '@vendure/ui-components'

interface AlertProps {
  blok: AlertStoryblok
}

export const Alert: React.FC<AlertProps> = ({ blok }) => {
  return (
    <CustomAlert
      variant={
        blok.type == '' || blok.type === undefined ? 'default' : blok.type
      }
      className="my-6 md:my-10"
    >
      <div>
        {/* TODO: Add icon */}
        {blok.title && <AlertTitle>{blok.title}</AlertTitle>}
        <AlertDescription>
          {blok.body?.map((item) => (
            <StoryblokComponent
              blok={item}
              key={item._uid}
            />
          ))}
        </AlertDescription>
      </div>
    </CustomAlert>
  )
}
