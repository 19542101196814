'use client'

import type { CompareSliderStoryblok } from '@vendure/codegen/storyblok'
import { GradientCard } from '@vendure/website-components/client'
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from 'react-compare-slider'

interface IComepareSlider {
  blok: CompareSliderStoryblok
}

export const CompareSlider: React.FC<IComepareSlider> = ({ blok }) => {
  return (
    <GradientCard>
      <ReactCompareSlider
        itemOne={
          <ReactCompareSliderImage
            src={blok.image1?.filename}
            alt={blok.image1?.alt}
          />
        }
        itemTwo={
          <ReactCompareSliderImage
            src={blok.image2?.filename}
            alt={blok.image2?.alt}
          />
        }
      />
    </GradientCard>
  )
}
