'use client'

import { StoryblokComponent, storyblokEditable } from '@storyblok/react/rsc'
import type { LandingPageConfigDemoStoryblok } from '@vendure/codegen/storyblok'
import { RichText, TitleWithAccent } from '@vendure/website-components/client'
import classNames from 'classnames'
import { useInView } from 'framer-motion'
import type { PropsWithChildren } from 'react'
import { useEffect, useRef, useState } from 'react'
import { CodeWriter, createOrderedArray } from './CodeWriter'
import {
  assetServerPluginBlocks,
  authBlocks,
  baseCodeBlocks,
  customFieldBlocks,
  elasticPluginBlocks,
  emailPluginBlocks,
  hardenPluginBlocks,
  multivendorPluginBlocks,
  redisPluginBlocks,
  stripePluginBlocks,
} from './code-blocks'

interface ILandingPageConfigDemo {
  blok: LandingPageConfigDemoStoryblok
}

export const LandingPageConfigDemo = (props: ILandingPageConfigDemo) => {
  const [showAsset, setShowAsset] = useState(false)
  const [showStripe, setShowStripe] = useState(false)
  const [showElastic, setShowElastic] = useState(false)
  const [showEmail, setShowEmail] = useState(false)
  const [showHarden, setShowHarden] = useState(false)
  const [showCustomFields, setShowCustomFields] = useState(false)
  const [showRedis, setShowRedis] = useState(false)
  const [showMultivendor, setShowMultivendor] = useState(false)
  const [showAuth, setShowAuth] = useState(false)
  const [isAutoplaying, setIsAutoplaying] = useState(true)
  const [currentStep, setCurrentStep] = useState(0)
  const featureBoxRef = useRef<HTMLDivElement>(null)
  const sectionRef = useRef(null)
  const isInView = useInView(sectionRef)

  const features = [
    {
      label: 'Define custom fields',
      setter: setShowCustomFields,
      state: showCustomFields,
      blocks: customFieldBlocks,
    },
    {
      label: 'Integrate AWS S3',
      setter: setShowAsset,
      state: showAsset,
      blocks: assetServerPluginBlocks,
    },
    {
      label: 'Connect custom authentication',
      setter: setShowAuth,
      state: showAuth,
      blocks: authBlocks,
    },
    {
      label: 'Add Stripe payments',
      setter: setShowStripe,
      state: showStripe,
      blocks: stripePluginBlocks,
    },
    {
      label: 'Integrate with SendGrid',
      setter: setShowEmail,
      state: showEmail,
      blocks: emailPluginBlocks,
    },
    {
      label: 'Elasticsearch for product search',
      setter: setShowElastic,
      state: showElastic,
      blocks: elasticPluginBlocks,
    },
    {
      label: 'Integrate with Redis',
      setter: setShowRedis,
      state: showRedis,
      blocks: redisPluginBlocks,
    },
    {
      label: 'Harden for production',
      setter: setShowHarden,
      state: showHarden,
      blocks: hardenPluginBlocks,
    },
    {
      label: 'Build a multi-vendor marketplace',
      setter: setShowMultivendor,
      state: showMultivendor,
      blocks: multivendorPluginBlocks,
    },
  ]

  const blocksArray = [...baseCodeBlocks]
  for (const feature of features) {
    if (feature.state) {
      blocksArray.push(...feature.blocks)
    }
  }

  const orderedBlocks = createOrderedArray(blocksArray)

  useEffect(() => {
    if (isAutoplaying && isInView) {
      const id = setTimeout(() => {
        features
          .map((f) => f.setter)
          .forEach((setter, i) => {
            setter(i === currentStep)
          })
        const scrollBox = featureBoxRef.current?.firstElementChild
        if (scrollBox) {
          const height = scrollBox.getBoundingClientRect().height
          const top = Math.max(
            0,
            height * ((currentStep + 1) / features.length) - 24,
          )
          scrollBox.scrollTo({ top, behavior: 'smooth' })
        }
      }, 1500)
      return () => {
        clearTimeout(id)
      }
    }
  }, [currentStep, isAutoplaying, isInView])

  useEffect(() => {
    if (!isAutoplaying) {
      setIsAutoplaying(features.every((f) => !f.state))
    }
  }, [features.map((f) => f.state.toString()).join('')])

  const onComplete = () => {
    const nextStep = (currentStep + 1) % features.length
    setCurrentStep(nextStep)
  }
  const onButtonClick = (toggleFn: () => void) => () => {
    toggleFn()
    setIsAutoplaying(false)
  }

  return (
    <div ref={sectionRef}>
      <div className="text-center">
        <TitleWithAccent
          tag="h2"
          title={props.blok.title}
          containerProps={{
            className: 'mb-4 text-5xl',
          }}
        />
        <RichText document={props.blok.text} />
      </div>
      <div
        className="mt-8 grid grid-cols-1 items-start gap-2 md:grid-cols-2 lg:gap-14"
        {...storyblokEditable(props.blok)}
      >
        <div>
          <CodeWriter
            blocks={orderedBlocks}
            delayMs={16}
            filename="src/vendure-config.ts"
            onComplete={onComplete}
            play={isInView}
          />
        </div>
        <div
          className="pt-1"
          ref={featureBoxRef}
        >
          <div className="mb-10 hidden max-h-40 flex-col items-center gap-2 overflow-auto md:max-h-none md:items-start lg:flex ">
            {features.map(({ label, setter, state }) => (
              <FeatureToggle
                key={label}
                enabled={state}
                onClick={onButtonClick(() => {
                  setter(!state)
                })}
              >
                {label}
              </FeatureToggle>
            ))}
          </div>
          {props.blok.bloks?.map((item) => (
            <StoryblokComponent
              blok={item}
              key={item._uid}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export const FeatureToggle = (
  props: PropsWithChildren<{ enabled: boolean; onClick: () => void }>,
) => {
  return (
    <button
      onClick={props.onClick}
      className={classNames([
        'inline-flex items-center gap-4 bg-transparent text-lg font-medium transition-all',
        props.enabled ? 'text-slate-200' : 'text-slate-500',
      ])}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        style={{
          width: '20px',
          height: '20px',
          fill: props.enabled ? '#ffffff' : 'rgba(82,82,82,0.39)',
        }}
      >
        <path
          fillRule="evenodd"
          d="M6.28 5.22a.75.75 0 010 1.06L2.56 10l3.72 3.72a.75.75 0 01-1.06 1.06L.97 10.53a.75.75 0 010-1.06l4.25-4.25a.75.75 0 011.06 0zm7.44 0a.75.75 0 011.06 0l4.25 4.25a.75.75 0 010 1.06l-4.25 4.25a.75.75 0 01-1.06-1.06L17.44 10l-3.72-3.72a.75.75 0 010-1.06zM11.377 2.011a.75.75 0 01.612.867l-2.5 14.5a.75.75 0 01-1.478-.255l2.5-14.5a.75.75 0 01.866-.612z"
          clipRule="evenodd"
        />
      </svg>
      {props.children}
    </button>
  )
}
