import { drawImage } from './draw-image'

export class Hub {
  imageElement: HTMLImageElement

  constructor (imageSrc: string) {
    this.imageElement = new Image()
    this.imageElement.src = imageSrc
  }

  draw (context: CanvasRenderingContext2D, width: number, height: number) {
    const radius = width / 10
    context.beginPath()
    // fill with radial graient from #040922 at the edge to #0a2942 at the center
    const gradient = context.createRadialGradient(width / 2, height / 2, 0, width / 2, height / 2, radius)
    gradient.addColorStop(1, '#040922')
    gradient.addColorStop(0, '#0a2942')
    context.fillStyle = gradient
    // context.arc(width / 2, height / 2, radius, 0, 2 * Math.PI)
    context.roundRect(width / 2 - radius, height / 2 - radius, radius * 2, radius * 2, 20)
    context.fill()

    context.shadowColor = 'rgba(23, 193, 255, 0.5)'
    context.shadowBlur = 40
    context.shadowOffsetX = 0
    context.shadowOffsetY = 0
    context.strokeStyle = 'rgba(23, 193, 255, 0.3)'
    context.stroke()

    context.closePath()

    const maxDimension = radius * 1.2
    drawImage(context, this.imageElement, width / 2, height / 2 + 6, width, height, maxDimension)
  }
}
