import { FactStoryblok } from '@vendure/codegen/storyblok'
import { abbreviateNumber } from 'js-abbreviation-number'

interface FactProps {
  blok: FactStoryblok
}

export const Fact: React.FC<FactProps> = ({ blok }) => {
  return (
    <div className="flex flex-col items-start gap-1">
      <span className="text-4xl font-bold tracking-tighter text-white md:text-6xl">
        {blok.abbreviate
          ? abbreviateNumber(Number(blok.number), 0)
          : blok.number}
      </span>
      <span className="text-lg text-white/80">{blok.label}</span>
    </div>
  )
}
