'use client'

import type {
  ArticleStoryblok,
  BlogArticlesStoryblok,
} from '@vendure/codegen/storyblok'
import { ArticleCard } from '@vendure/website-components/client'
import type { StoryblokStory } from 'storyblok-generate-ts'

interface IBlogArticles {
  blok: BlogArticlesStoryblok
}

export const BlogArticles = ({ blok }: IBlogArticles) => {
  return (
    <div className="columns-1 gap-[60px] md:columns-2">
      {blok.articles?.map((item: any) => {
        const article = item as unknown as StoryblokStory<ArticleStoryblok>

        return (
          <ArticleCard
            key={article.uuid}
            article={article}
          />
        )
      })}
    </div>
  )
}
