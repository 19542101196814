import type { CodeBlock } from './CodeWriter'

export const baseCodeBlocks: CodeBlock[] = [
  {
    id: 'coreImports',
    lines: ['import { VendureConfig } from \'@vendure/core\';'],
  },
  {
    id: 'configStart',
    insertAfter: 'coreImports',
    lines: [
      '',
      'export export const config: VendureConfig = {',
      '  apiOptions: {',
      '      port: 3000,',
      "      adminApiPath: 'admin-api',",
      "      shopApiPath: 'shop-api',",
      '  },',
    ],
  },
  {
    id: 'pluginsStart',
    insertAfter: 'configStart',
    lines: [
      '  plugins: [',
    ],
  },
  {
    id: 'pluginsEnd',
    insertAfter: 'pluginsStart',
    lines: [
      '  ],',
    ],
  },
  {
    id: 'configEnd',
    insertAfter: 'pluginsEnd',
    lines: ['};'],
  }]

export const assetServerPluginBlocks: CodeBlock[] = [
  {
    id: 'assetServerImport',
    insertAfter: 'coreImports',
    lines: [
      "import { AssetServerPlugin, configureS3AssetStorage } from '@vendure/asset-server-plugin';",
    ],
  },
  {
    id: 'assetServerPlugin',
    insertAfter: 'pluginsStart',
    lines: [
      '    AssetServerPlugin.init({',
      '      route: \'assets\',',
      '      assetUrlPrefix: \'https://demo.vendure.io/assets/\',',
      '      storageStrategyFactory: configureS3AssetStorage({',
      '        bucket: \'my-product-images\'',
      '      }),',
      '    }),',
    ],
  },
]

export const customFieldBlocks: CodeBlock[] = [
  {
    id: 'customFields',
    insertAfter: 'configStart',
    lines: [
      '  customFields: {',
      '    ProductVariant: [{',
      '      name: \'ISBN\',',
      '      type: \'string\',',
      '      required: true,',
      '    }, {',
      '      name: \'MSRP\',',
      '      type: \'int\',',
      '      ui: { component: \'currency-form-input\' },',
      '    }],',
      '  },',
    ],
  },
]

export const stripePluginBlocks: CodeBlock[] = [
  {
    id: 'stripeImport',
    insertAfter: 'coreImports',
    lines: [
      "import { StripePlugin } from '@vendure/payments-plugin/package/stripe';",
    ],
  },
  {
    id: 'stripePlugin',
    insertAfter: 'pluginsStart',
    lines: [
      '    StripePlugin.init({',
      '      storeCustomersInStripe: true,',
      '    }),',
    ],
  },
]

export const emailPluginBlocks: CodeBlock[] = [
  {
    id: 'emailImport',
    insertAfter: 'coreImports',
    lines: [
      "import { defaultEmailHandlers, EmailPlugin } from '@vendure/email-plugin';",
      "import { SendgridEmailSender } from './plugins/sendgrid';",
    ],
  },
  {
    id: 'emailPlugin',
    insertAfter: 'pluginsStart',
    lines: [
      '    EmailPlugin.init({',
      '      handlers: defaultEmailHandlers,',
      '      emailSender: new SendgridEmailSender({',
      '        apiKey: process.env.SENDGRID_API_KEY,',
      '      }),',
      '    }),',
    ],
  },
]

export const hardenPluginBlocks: CodeBlock[] = [
  {
    id: 'hardenImport',
    insertAfter: 'coreImports',
    lines: [
      "import { HardenPlugin } from '@vendure/harden-plugin';",
    ],
  },
  {
    id: 'hardenPlugin',
    insertAfter: 'pluginsStart',
    lines: [
      '    HardenPlugin.init({',
      '      maxQueryComplexity: 650,',
      '      apiMode: process.env.APP_ENV === \'dev\' ? \'dev\' : \'prod\'',
      '    }),',
    ],
  },
]

export const elasticPluginBlocks: CodeBlock[] = [
  {
    id: 'elasticImport',
    insertAfter: 'coreImports',
    lines: [
      "import { ElasticsearchPlugin } from '@vendure/elasticsearch-plugin';",
    ],
  },
  {
    id: 'elasticPlugin',
    insertAfter: 'pluginsStart',
    lines: [
      '    ElasticsearchPlugin.init({',
      '      host: process.env.ELASTICSEARCH_HOST,',
      '      port: process.env.ELASTICSEARCH_PORT,',
      '    }),',
    ],
  },
]

export const redisPluginBlocks: CodeBlock[] = [
  {
    id: 'bullmqImport',
    insertAfter: 'coreImports',
    lines: [
      "import { BullMQJobQueuePlugin } from '@vendure/job-queue-plugin/package/bullmq';",
    ],
  },
  {
    id: 'bullmqPlugin',
    insertAfter: 'pluginsStart',
    lines: [
      '    BullMQJobQueuePlugin.init({',
      '      connection: { port: process.env.REDIS_PORT },',
      '      workerOptions: { concurrency: 10 },',
      '    }),',
    ],
  },
]

export const multivendorPluginBlocks: CodeBlock[] = [
  {
    id: 'multivendorImport',
    insertAfter: 'coreImports',
    lines: [
      "import { MultivendorPlugin } from './plugins/multivendor-plugin';",
    ],
  },
  {
    id: 'multivendorPlugin',
    insertAfter: 'pluginsStart',
    lines: [
      '    MultivendorPlugin.init({',
      '      platformFeePercent: 10,',
      '      platformFeeSKU: \'PLATFORM\'',
      '    }),',
    ],
  },
]

export const authBlocks: CodeBlock[] = [
  {
    id: 'authImport',
    insertAfter: 'coreImports',
    lines: [
      "import { KeycloakAuthStrategy, AzureADAuthStrategy } from './config/auth';",
    ],
  },
  {
    id: 'authPlugin',
    insertAfter: 'configStart',
    lines: [
      '  authOptions: {',
      '    adminAuthenticationStrategy: [',
      '      new KeycloakAuthStrategy(),',
      '      new AzureADAuthStrategy(),',
      '    ],',
      '  },',
    ],
  },
]
