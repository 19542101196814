'use client'

import { storyblokEditable } from '@storyblok/react/rsc'
import {
  IntegrationItemStoryblok,
  IntegrationsTeaserStoryblok,
} from '@vendure/codegen/storyblok'
import { cn } from '@vendure/helpers'
import {
  ActionButton,
  CustomImage,
  GradientCard,
  PreTitle,
  RichText,
  TitleWithAccent,
} from '@vendure/website-components/client'
import { useMemo, useState } from 'react'
import { useInterval } from 'usehooks-ts'

interface IntegrationsTeaserProps {
  blok: IntegrationsTeaserStoryblok
}

export const IntegrationsTeaser: React.FC<IntegrationsTeaserProps> = ({
  blok,
}) => {
  const PER_CHUNK = 6
  const [focusedIndex, setFocusedIndex] = useState<number | undefined>(
    undefined,
  )

  useInterval(() => {
    if (blok.integrations) {
      const randomIndex = Math.floor(Math.random() * blok.integrations.length)
      setFocusedIndex(randomIndex)
    }
  }, 2000)

  const integrationItemChunks = useMemo(() => {
    return blok.integrations?.reduce(
      (resultArray: Array<IntegrationItemStoryblok[]>, item, index) => {
        const chunkIndex = Math.floor(index / PER_CHUNK)

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [] // start a new chunk
        }

        resultArray[chunkIndex].push(item)

        return resultArray
      },
      [],
    )
  }, [blok.integrations])

  return (
    <div {...storyblokEditable(blok)}>
      <div className="grid grid-cols-1 gap-20 md:grid-cols-2">
        <div>
          <PreTitle containerProps={{ className: 'mb-4' }}>
            {blok.preTitle}
          </PreTitle>
          <TitleWithAccent
            containerProps={{
              className: 'mb-4 text-4xl',
            }}
            title={blok.title}
          ></TitleWithAccent>
          <RichText document={blok.content}></RichText>
          <div className="mt-6 flex items-center">
            {blok.buttons?.map((item) => {
              return (
                <ActionButton
                  key={item._uid}
                  {...item}
                ></ActionButton>
              )
            })}
          </div>
        </div>
        <div className="columns-3 md:columns-5">
          {blok.integrations?.map((item, idx) => {
            return (
              <GradientCard
                key={item._uid}
                outerProps={{
                  className: cn(
                    'rounded-lg shrink-0 mb-3 h-28 w-28 rounded-lg md:mb-8 transition-all duration-800 ease-in-out hover:shadow-lead-xl',
                    focusedIndex === idx ? 'shadow-lead-xl' : '',
                  ),
                }}
                containerProps={{
                  className:
                    'bg-gradient-to-b from-slate-800 to-slate-900 p-6 flex items-center justify-center',
                }}
              >
                {item.icon?.filename && (
                  <CustomImage
                    width={300}
                    height={300}
                    alt={item.name ?? ''}
                    src={item.icon?.filename}
                    className="brightness-0 invert"
                  ></CustomImage>
                )}
              </GradientCard>
            )
          })}
        </div>
      </div>
    </div>
  )
}
