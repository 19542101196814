'use client'

import { storyblokEditable } from '@storyblok/react/rsc'
import {
  CaseStudyCarouselStoryblok,
  CaseStudyStoryblok,
  CustomerStoryblok,
} from '@vendure/codegen/storyblok'
import { Button } from '@vendure/ui-components'
import {
  CustomImage,
  CustomLink,
  GradientCard,
  Heading,
  RichText,
} from '@vendure/website-components/client'
import { motion, useScroll, useTransform } from 'framer-motion'
import React, { useRef } from 'react'
import { HiArrowRight } from 'react-icons/hi'
import { useIntl } from 'react-intl'
import { StoryblokStory } from 'storyblok-generate-ts'
import { Fact } from './Fact'

interface CaseStudyWithCustomer extends CaseStudyStoryblok {
  customer?: StoryblokStory<CustomerStoryblok>
}
interface CaseStudyCarouselStoryblokWithItems
  extends Omit<CaseStudyCarouselStoryblok, 'items'> {
  items: StoryblokStory<CaseStudyWithCustomer>[]
}

interface CaseStudyCarouselProps {
  blok: CaseStudyCarouselStoryblokWithItems
}

interface CaseStudyCarouselItemProps {
  item: StoryblokStory<CaseStudyWithCustomer>
  idx: number
  targetScale: any
  scrollYProgress: any
  range: any
}

export const CaseStudyCarouselItem: React.FC<CaseStudyCarouselItemProps> = ({
  item,
  idx,
  targetScale,
  scrollYProgress,
  range,
}) => {
  const scale = useTransform(scrollYProgress, range, [1, targetScale])
  const opacity = useTransform(scrollYProgress, [0, 1], [0.4, 1])
  const intl = useIntl()

  return (
    <motion.div
      className="sticky top-0 my-10"
      style={{
        top: `calc(5vh + ${idx * 40}px)`,
        scale: scale,
      }}
    >
      <GradientCard
        outerProps={{
          className:
            'h-screen md:h-[50vh] md:min-h-[600px] shadow-lg rounded-2xl',
        }}
        containerProps={{
          className: 'relative',
        }}
      >
        {item.content.image?.filename && (
          <CustomImage
            src={item.content.image.filename}
            alt={item.content.image.alt ?? ''}
            className="absolute left-0 top-0 h-full w-full object-cover object-center"
            fill
          ></CustomImage>
        )}
        <motion.div
          style={{ opacity: opacity }}
          className="absolute bottom-0 left-0 right-0 top-0 z-10 flex flex-col items-start justify-end gap-6 bg-black/50 p-4 md:flex-row md:items-end md:justify-start md:gap-20 md:p-20"
        >
          <div className="max-w-[800px]">
            {item.content.customer?.content.logo?.filename && (
              <CustomImage
                src={item.content.customer?.content.logo?.filename}
                className="h-8 w-auto opacity-80  brightness-0 invert saturate-100 md:h-[50px]"
                width={300}
                height={150}
                alt={item.content.customer.content.name ?? ''}
              ></CustomImage>
            )}
            <span className="mb-4 mt-6 block h-[1px] w-[60px] bg-white/20"></span>
            <Heading className="mb-4 block text-2xl font-bold tracking-tighter text-white md:text-5xl">
              {item.content.previewTitle || item.content.name}
            </Heading>
            <RichText
              containerProps={{ className: 'text-lg' }}
              document={item.content.previewContent}
            ></RichText>
            <Button
              asChild
              variant={'lead'}
              className="mt-4"
            >
              <CustomLink href={item.full_slug}>
                {intl.formatMessage({ id: 'case_study.action.view' })}
                <HiArrowRight className="ml-1"></HiArrowRight>
              </CustomLink>
            </Button>
          </div>
          <div className="grid grid-cols-1 gap-10 md:grow md:grid-cols-3">
            {item.content.facts?.map((item) => {
              return (
                <div
                  className="border-primary-400 border-l-2 px-6"
                  key={item._uid}
                >
                  <Fact blok={item}></Fact>
                </div>
              )
            })}
          </div>
        </motion.div>
      </GradientCard>
    </motion.div>
  )
}

export const CaseStudyCarousel: React.FC<CaseStudyCarouselProps> = ({
  blok,
}) => {
  const container = useRef(null)

  const { scrollYProgress } = useScroll({
    target: container,
    offset: ['start center', 'start start'],
  })

  return (
    <div
      ref={container}
      className="relative"
      {...storyblokEditable(blok)}
    >
      {blok.items.map((item, idx) => {
        const targetScale = 1 - (blok.items.length - idx) * 0.05
        return (
          <CaseStudyCarouselItem
            idx={idx}
            key={item.uuid}
            item={item}
            range={[idx * 0.25, 1]}
            scrollYProgress={scrollYProgress}
            targetScale={targetScale}
          ></CaseStudyCarouselItem>
        )
      })}
    </div>
  )
}
