import { drawImage } from './draw-image'

export class Node {
  private readonly seed: number = Math.random()
  private readonly initialOffset = this.seed * 180
  private intensity = 0
  private angle = 0
  private x = 0
  private y = 0
  private errorCount = 0
  imageElement: HTMLImageElement

  constructor (
    public size: number,
    private readonly orbitRadius: number,
    private readonly index: number,
    private readonly imageSrc: string,
  ) {
    this.imageElement = new Image()
    this.imageElement.src = imageSrc
  }

  draw (context: CanvasRenderingContext2D, width: number, height: number) {
    const radius = this.size / 4
    // if (this.intensity < 0.01) {
    //   return;
    // }

    this.drawCircle(context, radius)
    this.drawLogo(context, radius)
    // this.drawDebugInfo(context);
    this.drawConnection(context, width, height, radius)
  }

  private drawDebugInfo (context: CanvasRenderingContext2D) {
    // draw the index in the center of the circle
    context.fillStyle = '#ffffff'
    context.font = '20px Arial'
    context.textAlign = 'center'
    context.textBaseline = 'middle'
    context.fillText(Math.round(Math.cos(this.angle) * 100).toString(), this.x, this.y)
    // context.fillText(this.intensity.toFixed(2), this.x, this.y)
    context.closePath()
  }

  private drawCircle (context: CanvasRenderingContext2D, radius: number) {
    context.beginPath()
    context.fillStyle = `rgba(11, 37, 59, ${Math.min(this.intensity ** 10 + 0.2, 1)})`
    context.arc(this.x, this.y, radius, 0, 2 * Math.PI)
    context.fill()
    context.strokeStyle = `rgba(44, 193, 255, ${Math.max(this.intensity ** 10 - 0.4, 0)})`
    context.stroke()
  }

  private drawConnection (context: CanvasRenderingContext2D, width: number, height: number, radius: number) {
    // draw a line to the center of the canvas
    context.beginPath()
    // edge of the node circle
    const deltaX = this.x - width / 2
    const deltaY = this.y - height / 2
    context.moveTo(
      Math.cos(this.angle) * radius * -1 + width / 2 + Math.min(this.intensity ** 4, 1) * deltaX,
      Math.sin(this.angle) * radius * -1 + height / 2 + Math.min(this.intensity ** 4, 1) * deltaY,
    )
    // towards center
    context.lineTo(
      width / 2,
      height / 2)

    context.strokeStyle = `rgba(44, 193, 255, ${Math.max(this.intensity - 0.2, 0)})`
    context.shadowColor = 'rgba(23, 193, 255, 0.5)'
    const initialShadowBlur = context.shadowBlur
    context.shadowBlur = 10 * this.intensity ** 2
    context.stroke()
    context.shadowBlur = initialShadowBlur
  }

  private drawLogo (context: CanvasRenderingContext2D, radius: number) {
    if (this.errorCount > 10) {
      return
    }
    // context.beginPath()
    // context.fillStyle = `rgba(44, 193, 255, ${this.intensity ** 10})`;
    // context.arc(this.x, this.y, radius, 0, 2 * Math.PI)
    // context.fill();

    context.globalAlpha = Math.max(this.intensity ** 10 - 0.2, 0)
    try {
      drawImage(context, this.imageElement, this.x, this.y, this.size, this.size, radius * 1.2)
    } catch (e: any) {
      console.error(e.message)
      this.errorCount++
      if (this.errorCount > 10) {
        console.error(`Could not load image ${this.imageSrc}. Will no longer render this node`)
      }
    }
    context.globalAlpha = 1
  }

  update (time: number, width: number, height: number, mousePos: { x: number, y: number } | null) {
    this.angle = this.initialOffset + (time * this.size ** 1.7 / 50000000)
    this.x = width / 2 + this.orbitRadius * width * Math.cos(this.angle)
    this.y = height / 2 + this.orbitRadius * height * Math.sin(this.angle)

    // a flattened sin wave with cutoff at the top and bottom
    this.intensity = Math.max(Math.min(Math.sin(((time + this.seed * 50000) / 2000)) * 2, 1), 0)

    if (mousePos) {
      if (!this.intensity) {
        const deltaX = this.x - mousePos.x
        const deltaY = this.y - mousePos.y
        const distance = Math.sqrt(deltaX ** 2 + deltaY ** 2)
        this.intensity = distance < 100 ? 1 - (distance / 100) ** 10 : 0
      }
    }
  }
}
