'use client'

import { storyblokEditable } from '@storyblok/react/rsc'
import type {
  CaseStudyGridStoryblok,
  CaseStudyStoryblok,
} from '@vendure/codegen/storyblok'
import { getCaseStudies } from '@vendure/helpers'
import {
  CaseStudyCard,
  CaseStudyGridFilter,
} from '@vendure/website-components/client'
import { useState } from 'react'
import type { StoryblokStory } from 'storyblok-generate-ts'

interface CaseStudyGridWithCaseStudies extends CaseStudyGridStoryblok {
  caseStudies?: Array<StoryblokStory<CaseStudyStoryblok>>
}

interface ICaseStudyGrid {
  blok: CaseStudyGridWithCaseStudies
  containerBackgroundColor?: any
}

function extractAllValuesOfField(
  caseStudies: Array<StoryblokStory<CaseStudyStoryblok>>,
  field: string,
) {
  const values = []
  for (const caseStudy of caseStudies) {
    values.push(caseStudy.content[field])
  }

  const processed: string[] = values.reduce(
    (accumulator: string[], currentValue: string) => {
      if (currentValue.includes(',')) {
        const split = currentValue.split(',')
        return [...accumulator, ...split]
      }

      return [...accumulator, currentValue]
    },
    [],
  )

  const set = new Set(processed)

  return [...set]
}

function extractAllLocations(
  caseStudies: Array<StoryblokStory<CaseStudyStoryblok>>,
) {
  return extractAllValuesOfField(caseStudies, 'location')
}

function extractAllCategories(
  caseStudies: Array<StoryblokStory<CaseStudyStoryblok>>,
) {
  return extractAllValuesOfField(caseStudies, 'category')
}

export const CaseStudyGrid = ({
  blok,
  containerBackgroundColor,
}: ICaseStudyGrid) => {
  const [selectedCategories, setSelectedCategories] = useState<string[]>([])
  const [selectedLocations, setSelectedLocations] = useState<string[]>([])
  const [caseStudies, setCaseStudies] = useState<
    Array<StoryblokStory<CaseStudyStoryblok>>
  >(blok.caseStudies ?? [])

  const locations = extractAllLocations(blok.caseStudies ?? [])
  const categories = extractAllCategories(blok.caseStudies ?? [])

  const loadCaseStudies = async () => {
    const caseStudies = await getCaseStudies(
      'en',
      selectedCategories,
      selectedLocations,
    )
    setCaseStudies(caseStudies)
  }

  function onFilterChange(
    selectedCategories: string[],
    selectedLocations: string[],
  ) {
    setSelectedCategories([...selectedCategories])
    setSelectedLocations([...selectedLocations])
    loadCaseStudies()
  }

  return (
    <div {...storyblokEditable(blok)}>
      <CaseStudyGridFilter
        selectedCategories={selectedCategories}
        selectedLocations={selectedLocations}
        onFilterChange={onFilterChange}
        categoryOptions={categories}
        locationOptions={locations}
      />
      <div className="mt-5 grid  gap-6 sm:grid-cols-1 md:gap-10 lg:grid-cols-3">
        {caseStudies.map((item) => {
          return (
            <CaseStudyCard
              key={item.uuid}
              // @ts-ignore
              caseStudy={item}
            />
          )
        })}
      </div>
    </div>
  )
}
