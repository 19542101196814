'use client'

import { Canvas } from './Canvas'
import { useEffect, useRef, useState } from 'react'
import { Hub } from './Hub'
import { Node } from './Node'

export interface LogoCloudProps {
  images: string[]
  playing: boolean
}

export const LogoCloud = (props: LogoCloudProps) => {
  const width = 800
  const height = 600
  const nodes = useRef<Node[]>([])
  const hub = useRef<Hub>()

  const hovering = useRef(false)
  const mouseX = useRef(0)
  const mouseY = useRef(0)

  useEffect(() => {
    nodes.current = []
    let i = 0
    for (const imageSrc of props.images) {
      nodes.current.push(new Node(
        Math.random() * 80 + 50,
        Math.random() / 5 + 0.2,
        i,
        imageSrc,
      ))
      i++
    }
    hub.current = new Hub('./cube-logo-vector.svg')
  }, [])

  const tick = useRef((context: CanvasRenderingContext2D, time: number) => {
    context.clearRect(0, 0, context.canvas.width, context.canvas.height)
    context.fillStyle = '#000000'
    for (const node of nodes.current.sort((a, b) => a.size - b.size)) {
      node.update(time, width, height, hovering ? { x: mouseX.current, y: mouseY.current } : null)
      node.draw(context, width, height)
    }
    hub.current?.draw(context, width, height)
  })

  const mouseMoveHandler = (event: React.MouseEvent<HTMLCanvasElement>) => {
    if (hovering.current) {
      mouseX.current = (event.nativeEvent.offsetX)
      mouseY.current = (
        event.nativeEvent.offsetY)
    }
  }

  return (
    <Canvas
      tick={tick.current}
      playing={props.playing}
      width={width}
      height={height}
      style={{ maxWidth: '100%', maxHeight: '100%', margin: 'auto' }}
      onMouseEnter={() => hovering.current = true}
      onMouseLeave={() => hovering.current = false}
      onMouseMove={mouseMoveHandler}
    />
  )
}
