import * as React from 'react'
import { withLineNumbers } from 'codejar/linenumbers'

interface CodejarOptions {
  tab: string
  indentOn: RegExp
  moveToNewLine: RegExp
  spellcheck: boolean
  catchTab: boolean
  preserveIdent: boolean
  addClosing: boolean
  history: boolean
  window: typeof window
}

interface Props {
  highlight: (e: HTMLElement) => void
  options?: Partial<CodejarOptions>
  code: string
  style: React.CSSProperties
  onUpdate: (code: string) => void
  lineNumbers?: boolean
}

/**
 * Based on https://github.com/guilhermelimak/react-codejar
 */
export const useCodeJar = (props: Props) => {
  const editorRef = React.useRef<HTMLDivElement>(null)
  const jar = React.useRef<import('codejar').CodeJar | null>(null)

  React.useEffect(() => {
    (async () => {
      if (!editorRef.current) return

      const CodeJar = (await import('codejar')).CodeJar

      const highlight = props.lineNumbers
        ? withLineNumbers(props.highlight)
        : props.highlight

      jar.current = CodeJar(editorRef.current, highlight, props.options)

      jar.current.updateCode(props.code)

      jar.current.onUpdate(txt => {
        if (!editorRef.current) return
        props.onUpdate(txt)
      })
    })()

    return () => jar.current?.destroy()
  }, [])

  React.useEffect(() => {
    if (!jar.current || !editorRef.current) return
    jar.current.updateCode(props.code)
  }, [props.code])

  React.useEffect(() => {
    if (!jar.current || !props.options) return

    jar.current.updateOptions(props.options)
  }, [props.options])

  return editorRef
}
