'use client'

import { StoryblokComponent, storyblokEditable } from '@storyblok/react/rsc'
import type { TabsStoryblok } from '@vendure/codegen/storyblok'
import { cn } from '@vendure/helpers'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@vendure/ui-components'
import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { useState } from 'react'

interface ITabs {
  blok: TabsStoryblok
  containerHorizontallyCentered?: boolean
}

export const ContentTabs = ({ blok, containerHorizontallyCentered }: ITabs) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  return (
    <Tabs
      defaultValue={blok.items?.at(currentIndex)?._uid}
      {...storyblokEditable(blok)}
    >
      <div
        className={cn(
          containerHorizontallyCentered ? 'justify-center md:flex' : 'block',
          'overflow-y-auto overflow-x-scroll md:overflow-x-auto',
        )}
      >
        <TabsList>
          {blok.items?.map((item) => {
            return (
              <TabsTrigger
                key={'tab-trigger-' + item._uid}
                value={item._uid}
              >
                {item.title}
              </TabsTrigger>
            )
          })}
        </TabsList>
      </div>

      {blok.items?.map((item) => {
        return (
          <AnimatePresence key={'tab-content-' + item._uid}>
            <TabsContent
              asChild
              className={classNames(
                containerHorizontallyCentered
                  ? 'mx-auto lg:max-w-screen-xl'
                  : '',
                'py-10',
              )}
              value={item._uid}
            >
              <motion.div
                initial={{ x: -25, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: 25, opacity: 0 }}
                transition={{
                  ease: 'easeInOut',
                  duration: 0.4,
                }}
              >
                {item.body?.map((nestedBlok) => (
                  <StoryblokComponent
                    blok={nestedBlok}
                    key={nestedBlok._uid}
                  />
                ))}
              </motion.div>
            </TabsContent>
          </AnimatePresence>
        )
      })}
    </Tabs>
  )
}
