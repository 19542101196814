export function drawImage (context: CanvasRenderingContext2D, image: HTMLImageElement, x: number, y: number, width: number, height: number, radius: number) {
  let ratio = 1
  if (image.width > image.height) {
    ratio = radius / image.width
  } else {
    ratio = radius / image.height
  }
  const imageWidth = image.width * ratio
  const imageHeight = image.height * ratio
  context.drawImage(image, x - imageWidth / 2, y - imageHeight / 2, imageWidth, imageHeight)
}
