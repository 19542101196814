'use client'

import type { TextStoryblok } from '@vendure/codegen/storyblok'
import { RichText } from '@vendure/website-components/client'

interface ICustomText {
  blok: TextStoryblok
}

export const CustomText = ({ blok }: ICustomText) => {
  return <RichText document={blok.text} />
}
