'use client'

import { StoryblokComponent, storyblokEditable } from '@storyblok/react/rsc'
import type { LandingPageConnectedLogosStoryblok } from '@vendure/codegen/storyblok'
import {
  ActionButton,
  PreTitle,
  RichText,
  TitleWithAccent,
} from '@vendure/website-components/client'
import { useInView } from 'framer-motion'
import { useRef } from 'react'
import { LogoCloud } from './LogoCloud'

interface ILandingPageConnexctedLogos {
  blok: LandingPageConnectedLogosStoryblok
  containerBackgroundColor?: string
}

export const LandingPageConnectedLogos = (
  props: ILandingPageConnexctedLogos,
) => {
  const blok = props.blok
  const sectionRef = useRef(null)
  const isInView = useInView(sectionRef)
  const useLightColors = props.containerBackgroundColor !== 'light'

  return (
    <div
      className="relative pb-20"
      ref={sectionRef}
      {...storyblokEditable(blok)}
    >
      <div className="relative -bottom-14 right-0 hidden text-center lg:absolute lg:-top-10 lg:bottom-0 lg:block">
        <LogoCloud
          images={props.blok.logos?.map((l) => l.filename) ?? []}
          playing={isInView}
        />
      </div>
      <div className="relative z-10 lg:max-w-[600px]">
        <div className="w-full">
          {blok.preTitle && (
            <PreTitle
              containerProps={{
                className: 'mb-4',
              }}
              tag={blok.preTitleTag}
            >
              {blok.preTitle}
            </PreTitle>
          )}
          <TitleWithAccent
            tag={blok.titleTag}
            title={blok.title}
            containerProps={{
              className: 'mb-4 text-5xl',
            }}
          />
          <RichText document={blok.text} />
          <div className="mt-8">
            {blok.bloks?.map((item) => {
              return (
                <StoryblokComponent
                  blok={item}
                  key={item._uid}
                />
              )
            })}
          </div>
          <div className="mt-8 items-center gap-12">
            {blok.buttons?.map((item) => {
              return (
                <ActionButton
                  key={item._uid}
                  {...item}
                />
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
