'use client'

import { StoryblokComponent, storyblokEditable } from '@storyblok/react/rsc'
import type { SnippetStoryblok } from '@vendure/codegen/storyblok'

interface ISnippet {
  blok: SnippetStoryblok
}

export const Snippet = ({ blok }: ISnippet) => {
  return (
    <div {...storyblokEditable(blok)}>
      {blok.bloks?.map((nestedBlok) => (
        <StoryblokComponent
          blok={nestedBlok}
          key={nestedBlok._uid}
        />
      ))}
    </div>
  )
}
