'use client'

import { useState } from 'react'
import { useInterval } from 'usehooks-ts'
import styles from './CodeWriter.module.css'
import { useCodeJar } from './useCodejar'

export interface CodeBlock {
  lines: string[]
  id: string
  insertAfter?: string
}

export interface CodeFrameProps {
  blocks: CodeBlock[]
  filename: string
  delayMs: number
  play: boolean
  onComplete?: () => void
}

export const CodeWriter = (props: CodeFrameProps) => {
  const [cursors, setCursors] = useState<Record<string, number>>({})
  const [allComplete, setAllComplete] = useState(false)

  useInterval(
    () => {
      const updatedCursors = { ...cursors }
      let allFinished = true
      for (const block of props.blocks) {
        const totalLength = block.lines.reduce((sum, l) => sum + l.length, 0)
        if (updatedCursors[block.id] == null) {
          updatedCursors[block.id] = 0
          allFinished = false
        } else if (updatedCursors[block.id] <= totalLength + 1) {
          updatedCursors[block.id] = updatedCursors[block.id] + 1
          allFinished = false
        }
      }
      const unusedBlocks = Object.keys(updatedCursors).filter(
        (id) => !props.blocks.find((b) => b.id === id),
      )
      for (const unusedBlock of unusedBlocks) {
        delete updatedCursors[unusedBlock]
      }
      if (!allFinished) {
        setAllComplete(false)
      }
      if (!allComplete && allFinished) {
        props.onComplete?.()
        setAllComplete(true)
      }
      setCursors(updatedCursors)
    },
    props.play ? props.delayMs : null,
  )

  const code = props.blocks
    .map((block) => {
      const progress = cursors[block.id] + block.lines.length
      const joinedLines = `${block.lines.join('\n').slice(0, progress)}\n`

      const isInProgress = progress < joinedLines.length
      return isInProgress ? `¬¬${joinedLines}¬¬` : joinedLines
    })
    .join('')
  const editorRef = useCodeJar({
    code,
    highlight: (editor) => {
      const code =
        editor.textContent
          ?.replace(/&/g, '&amp;')
          .replace(/</g, '&lt;')
          .replace(/>/g, '&gt;') ?? ''

      editor.innerHTML = simpleTsHighligher(code).replace(
        /¬¬([^¬]+)¬¬/g,
        `<span class="${styles['cw-typing']}">$1</span>`,
      )
      editor.contentEditable = 'false'
    },
    options: {},
    onUpdate: () => {},
    style: {},
  })
  return (
    <div className={styles.ide}>
      <div className={styles.chrome}>
        <div>{props.filename}</div>
      </div>
      <div
        ref={editorRef}
        className={styles['code-writer']}
      />
    </div>
  )
}

const keywords = [
  'import',
  'export',
  'from',
  'const',
  'let',
  'var',
  'function',
  'class',
  'interface',
  'extends',
  'implements',
  'new',
  'return',
  'if',
  'else',
  'for',
  'while',
  'do',
  'switch',
  'case',
  'break',
  'continue',
  'try',
  'catch',
  'finally',
  'throw',
  'async',
  'await',
  'of',
  'in',
  'as',
  'type',
  'namespace',
  'true',
  'false',
]

function simpleTsHighligher(code: string): string {
  return code.replace(
    /\b[a-z][a-zA-Z]+:|\b\w+\b|'[^'\n]+'|\.[a-zA-Z]+\(|\)/g,
    (token) => {
      if (keywords.includes(token.trim())) {
        return `<span class="${styles['cw-keyword']}">${token}</span>`
      }
      if (/["'][^'"]+["']/.test(token.trim())) {
        return `<span class="${styles['cw-string']}">${token}</span>`
      }
      // if is numeric string
      if (/\d+/.test(token)) {
        return `<span class="${styles['cw-number']}">${token}</span>`
      }
      if (/^[A-Z][a-z0-9_]+/.test(token.trim())) {
        return `<span class="${styles['cw-type']}">${token}</span>`
      }
      if (/\.[a-zA-Z]+\(|\)/.test(token.trim())) {
        return `<span class="${styles['cw-method']}">${token}</span>`
      }
      if (/\b[a-z][a-zA-Z]+:/.test(token.trim())) {
        return `<span class="${styles['cw-name']}">${token}</span>`
      }
      return token
    },
  )
}

export function createOrderedArray(codeBlocks: CodeBlock[]): CodeBlock[] {
  const codeBlockMap: Record<string, CodeBlock> = {}
  const visited: Record<string, boolean> = {}
  const ordering: CodeBlock[] = []

  // Create a map of code blocks using their IDs as keys
  for (const codeBlock of codeBlocks) {
    codeBlockMap[codeBlock.id] = codeBlock
  }

  function visit(id: string, ancestors: Set<string>) {
    if (visited[id]) {
      return // Code block already visited
    }

    const codeBlock = codeBlockMap[id]
    if (!codeBlock) {
      throw new Error(`Code block with ID '${id}' not found.`)
    }

    if (ancestors.has(id)) {
      throw new Error('Circular dependencies detected.')
    }

    ancestors.add(id)

    if (codeBlock.insertAfter) {
      visit(codeBlock.insertAfter, ancestors)
      const index = ordering.findIndex((b) => b.id === codeBlock.insertAfter)
      ordering.splice(index + 1, 0, codeBlock)
    } else {
      ordering.push(codeBlock)
    }
    visited[id] = true

    ancestors.delete(id)
  }

  for (const codeBlock of codeBlocks) {
    visit(codeBlock.id, new Set<string>())
  }

  return ordering
}
