'use client'

import { StoryblokComponent, storyblokEditable } from '@storyblok/react/rsc'
import type {
  IncludeSnippetStoryblok,
  SnippetStoryblok,
} from '@vendure/codegen/storyblok'
import type { StoryblokStory } from 'storyblok-generate-ts'

interface IncludeSnippetProps {
  blok: IncludeSnippetStoryblok
}

export const IncludeSnippet: React.FC<IncludeSnippetProps> = ({ blok }) => {
  const snippets = blok.snippets as Array<StoryblokStory<SnippetStoryblok>>

  return (
    <div {...storyblokEditable(blok)}>
      {snippets.map((snippet) => {
        return (
          <StoryblokComponent
            blok={snippet.content}
            key={snippet.uuid}
          />
        )
      })}
    </div>
  )
}
