'use client'

import { storyblokEditable } from '@storyblok/react/rsc'
import type { MainHeroStoryblok } from '@vendure/codegen/storyblok'
import { cn } from '@vendure/helpers'
import { Switch } from '@vendure/ui-components'
import AnimatedGridPattern from '@vendure/ui-components/magicui/animated-grid-pattern'
import {
  ActionButton,
  CustomImage,
  PreTitle,
  RichText,
  TitleWithAccent,
  useNavContext,
} from '@vendure/website-components/client'
import classNames from 'classnames'
import { motion, useScroll, useSpring, useTransform } from 'framer-motion'
import { useMemo, useState } from 'react'
import { HiMoon, HiSun } from 'react-icons/hi'

interface IMainHero {
  blok: MainHeroStoryblok
}

export const MainHero = ({ blok }: IMainHero) => {
  const hasImages = (blok.darkMode?.filename ?? blok.lightMode?.filename) !== ''
  const [showLight, setShowLight] = useState(false)
  const { dropdownOpen } = useNavContext()

  const { scrollYProgress } = useScroll()
  const appearPoint = 0.05
  const rotateX = useTransform(scrollYProgress, [0, appearPoint], [40, 0])
  const rotateXSpring = useSpring(rotateX, { mass: 0.3, damping: 20 })
  const cubicEase = (t: number) => t * t * t
  const shadowSize = useTransform(
    useSpring(scrollYProgress, { mass: 0.1 }),
    [0, appearPoint],
    [5, 80],
    {
      ease: cubicEase,
    },
  )
  const boxShadow = useTransform(
    shadowSize,
    (input) => `var(--tw-color-primary-400) 0px 6px ${input}px`,
  )

  const images = useMemo(() => {
    return (
      <div>
        <div
          className="relative px-2 lg:px-10"
          style={{ perspective: '2000px' }}
        >
          <motion.div
            style={{
              rotateX: rotateXSpring,
              boxShadow,
              borderRadius: '6px',
            }}
          >
            <div className="relative aspect-video overflow-hidden rounded-md">
              {blok.darkMode?.filename && (
                <CustomImage
                  src={blok.darkMode.filename}
                  className="absolute h-full w-full object-cover object-top"
                  width={1920}
                  height={1080}
                  priority={true}
                  alt=""
                />
              )}
              {blok.lightMode?.filename && (
                <CustomImage
                  src={blok.lightMode.filename}
                  width={1920}
                  height={1080}
                  priority={true}
                  className={classNames([
                    'absolute h-full w-full object-cover object-top transition-all',
                    showLight ? 'opacity-100' : 'opacity-0',
                  ])}
                  alt=""
                />
              )}
            </div>
          </motion.div>
          <LeftScreenshot
            blok={blok}
            showLight={showLight}
          />
          <RightScreenshot
            blok={blok}
            showLight={showLight}
          />
        </div>
        <div className="mb-20 mt-5 flex flex-row items-center justify-center gap-2">
          <HiMoon opacity={showLight ? 0.5 : 1} />
          <Switch
            aria-label="Switch dark to light mode"
            onCheckedChange={(val) => {
              setShowLight(val)
            }}
          />
          <HiSun opacity={showLight ? 1 : 0.5} />
        </div>
      </div>
    )
  }, [blok, showLight])

  return (
    <div
      className={cn({
        'relative mb-[100px] w-full overflow-x-clip overflow-y-visible transition-all md:mb-0':
          true,
        'bg-top-gradient-radial': !dropdownOpen,
      })}
      {...storyblokEditable(blok)}
    >
      <div className="items-end pb-0 pt-[150px] md:pb-[100px] md:pt-[160px] 2xl:pb-[130px] 2xl:pt-[250px]">
        <div className="container relative z-20">
          <div className="flex flex-col justify-center text-center">
            <div className="mx-auto md:max-w-[90%]">
              <PreTitle
                containerProps={{
                  className: 'mb-4',
                }}
                tag="h1"
              >
                {blok.preTitle}
              </PreTitle>
              <TitleWithAccent
                title={blok.title}
                containerProps={{
                  className:
                    'mx-auto mb-4 md:mb-8 max-w-screen-xl text-4xl font-bold tracking-tighter text-white md:text-7xl',
                }}
              ></TitleWithAccent>
              <RichText
                containerProps={{
                  className:
                    'mx-auto leading-normal text-white/70 md:max-w-screen-md md:text-xl',
                }}
                document={blok.text}
              />

              <div className="my-6 flex justify-center gap-2 md:my-12 md:gap-4">
                {blok.buttons?.map((item) => (
                  <ActionButton
                    key={item._uid}
                    {...item}
                  />
                ))}
              </div>
            </div>
          </div>
          {hasImages && images}
        </div>
      </div>
      <AnimatedGridPattern
        numSquares={30}
        maxOpacity={0.1}
        duration={3}
        repeatDelay={1}
        className={cn(
          'z-0 opacity-70 [mask-image:radial-gradient(800px_circle_at_center,white,transparent)]',
          'inset-x-0 inset-y-[-30%] h-full skew-y-12',
        )}
      />
    </div>
  )
}

const LeftScreenshot = (props: {
  blok: MainHeroStoryblok
  showLight: boolean
}) => {
  const { blok, showLight } = props
  const { scrollYProgress } = useScroll()
  const opacity = useTransform(scrollYProgress, [0.05, 0.06], [0, 1])
  const bottom = useTransform(
    scrollYProgress,
    [0.05, 0.12],
    // [isDesktop ? -340 : -100, isDesktop ? -160 : -70] TODO: check how to make responsive,
    [-340, -160],
  )
  const opacitySpring = useSpring(opacity, {
    stiffness: 50,
    mass: 2,
    restDelta: 0.001,
  })
  const bottomSpring = useSpring(bottom, { mass: 0.3, damping: 20 })

  return (
    <motion.div
      className={classNames([
        'shadow-primary-400/20 absolute -left-2 z-10 hidden overflow-hidden rounded-md border-[10px] shadow-2xl transition-all md:-left-[2vw] md:block lg:-left-[6vw]',
        showLight ? 'border-white' : 'border-[rgb(30,42,52)]',
        'w-[40vw] lg:w-[200px] xl:w-[300px]',
        'h-[41vw] lg:h-[210px] xl:h-[320px]',
      ])}
      style={{
        translateY: bottomSpring,
        opacity: opacitySpring,
      }}
    >
      <>
        {blok.darkLeft?.filename && (
          <CustomImage
            src={blok.darkLeft.filename}
            className="absolute"
            width={837}
            height={919}
            alt=""
          />
        )}
        {blok.lightLeft?.filename && (
          <CustomImage
            src={blok.lightLeft.filename}
            className={classNames([
              'absolute transition-all',
              showLight ? 'opacity-100' : 'opacity-0',
            ])}
            width={837}
            height={919}
            alt=""
          />
        )}
      </>
    </motion.div>
  )
}

const RightScreenshot = (props: {
  blok: MainHeroStoryblok
  showLight: boolean
}) => {
  const { blok, showLight } = props
  const { scrollYProgress } = useScroll()
  const opacity = useTransform(scrollYProgress, [0.02, 0.03], [0, 1])

  const bottom = useTransform(
    scrollYProgress,
    [0.05, 0.1],
    //[isDesktop ? -300 : -140, isDesktop ? -40 : -80] TODO: Check how to make that responsive,
    [-300, -80],
  )
  const opacitySpring = useSpring(opacity, {
    stiffness: 50,
    mass: 2,
    restDelta: 0.001,
  })
  const bottomSpring = useSpring(bottom, { mass: 0.3, damping: 20 })
  return (
    <motion.div
      className={classNames([
        'shadow-primary-400/20 absolute -right-[6rem] z-10 hidden overflow-hidden rounded-md border-[10px] shadow-2xl transition-all md:-right-[1vw] md:block lg:-right-[3vw]',
        showLight ? 'border-white' : 'border-[rgb(30,42,52)]',
        'w-[60vw] lg:w-[400px] xl:w-[500px]',
        'h-[30vw] lg:h-[190x] xl:h-[245px]',
      ])}
      style={{
        translateY: bottomSpring,
        opacity: opacitySpring,
      }}
    >
      <>
        {blok.darkRight?.filename && (
          <CustomImage
            src={blok.darkRight.filename}
            width={837}
            height={919}
            alt=""
            className="absolute"
          />
        )}
        {blok.lightRight?.filename && (
          <CustomImage
            src={blok.lightRight.filename}
            width={837}
            height={919}
            alt=""
            className={classNames([
              'absolute transition-all',
              showLight ? 'opacity-100' : 'opacity-0',
            ])}
          />
        )}
      </>
    </motion.div>
  )
}
