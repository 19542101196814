'use client'

import type { CanvasHTMLAttributes } from 'react'
import { useEffect, useRef, useState } from 'react'

type Props = {
  tick: (context: CanvasRenderingContext2D, frameCount: number) => void
  playing: boolean
} & CanvasHTMLAttributes<HTMLCanvasElement>

export const Canvas = (props: Props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const previousTimeRef = useRef<number>()
  const frameCount = useRef(0)
  const savedTick = useRef<(context: CanvasRenderingContext2D, frameCount: number) => void>()

  // Remember the latest callback.
  useEffect(() => {
    savedTick.current = props.tick
  }, [props.tick])

  const { tick, playing, ...rest } = props

  useEffect(() => {
    const canvas = canvasRef.current
    const context = canvas!.getContext('2d')
    let animationFrameId: any

    const render = (time: number) => {
      if (previousTimeRef.current != undefined && props.playing) {
        savedTick.current?.(context!, time)
      }
      previousTimeRef.current = time
      frameCount.current++

      animationFrameId = window.requestAnimationFrame(render)
    }
    render(new Date().getTime())

    return () => {
      window.cancelAnimationFrame(animationFrameId)
    }
  }, [tick, playing])

  return <canvas ref={canvasRef} {...rest}/>
}
